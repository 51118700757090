<template>
  <div>
    <p class="main-header-section">Персональные</p>

    <div class="d-flex flex-wrap mb-2">
      <div
        @click="showModal('personal')"
        class="badge-personal"
        style="cursor: pointer"
      >
        <feather-icon icon="EditIcon" size="16" color="dark" />
      </div>
      <div
        v-if="Offers.personal.to_income !== 'none'"      
        class="badge-personal"
      >
        <span class="user-select-none">до {{ Offers.personal.to_income }} ₽</span>
        <feather-icon
          @click="remove('to_income')"
          icon="XIcon"
          size="14"
          color="gray"
          style="cursor: pointer"
        />
      </div>
      <div
        class="badge-personal"
        v-for="(cat, i) in selectedCategories"
        :key="i"
      >
        <span class="user-select-none">{{ cat.name }}</span>
        <feather-icon
          @click="remove(cat.id, i)"
          icon="XIcon"
          size="14"
          color="gray"
          style="cursor: pointer"
        />
      </div>
    </div>

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant="transparent"
      style="flex-wrap: wrap; position: unset !important"
    >
      <b-card-body
        v-if="Offers.personal_tape.items.length === 0 && !is_loading"
        class="d-flex flex-column p-4"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="nodata-icon ml-auto mr-auto"></div>
          <p class="text-muted mt-1 ml-auto mr-auto text-center">
            Нет предложений
          </p>
        </div>
      </b-card-body>

      <div class="d-grid-cards-catalog" v-else-if="!is_loading">
        <offer-card-view
          v-for="(offer, index) in Offers.personal_tape.items"
          :key="index"
          :item="offer"
        />
      </div>
    </b-overlay>

    <div class="mt-2 btn-more-footer" v-if="Offers.personal_tape.has_more_pages && !is_loading">
      <center>
        <b-button
          v-if="!is_additional_loading"
          @click="loadMore"
          variant="primary-dark"
        >
          Показать еще
        </b-button>
        <b-button v-else variant="primary-dark" disabled>
          <b-spinner small type="grow"></b-spinner>
          Загрузка...
        </b-button>
      </center>
    </div>

    <b-modal
      id="setting-personal"
      v-model="modalsVisibility.personal"
      centered
      title="Настроить персональную ленту"
      hide-footer
      modal-class="setting-personal-container"
    >
      <settings-modal-content
        @close="closeModal"
        :personal="Offers.personal"
        @changePersonalTape="changePersonalTape"
        @unCheckCategories="unCheckCategories"
      />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import OfferCardView from "@/components/OfferCard";
import Offers from "@/modules/offers/";

import SettingsModalContent from "../modals/settings";

export default {
  data() {
    return {
      Offers,
      is_loading: false,
      is_additional_loading: false,
      modalsVisibility: {
        personal: false,
      },
    };
  },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {
      this.is_additional_loading = true;
      
      Offers.getPersonalTape({page: ++Offers.personal_tape.current_page}, () => {
        this.is_additional_loading = false;
      });
    },
    remove(id, i) {
      this.is_loading = true;
      let ids = [];
      let to_income = Offers.personal.to_income;

      Offers.personal.categories.forEach((cater) => {
        if (cater.personal && cater.id !== id) ids.push(cater.id);
      });

      if (id == 'to_income') {
        to_income = 'none';
      }

      Offers.updatePersonalTape(ids, to_income, "remove", (rsp) => {
        if (rsp.personal.categories.length == 0 && rsp.personal.to_income == 'none') {
          this.showModal("personal");
          this.is_loading = false;
        } else
          Offers.getPersonalTape({}, () => {
            this.is_loading = false;
          });
      });
    },
    changePersonalTape() {
      this.closeModal();
      this.is_loading = true;

      let ids = [];
      Offers.personal.categories.forEach((cater) => {
        if (cater.personal) ids.push(cater.id);
      });

      Offers.updatePersonalTape(ids, Offers.personal.to_income , "add", () => {
        Offers.getPersonalTape({}, () => {
          this.is_loading = false;
        });
      });
    },
    unCheckCategories() {
      Offers.personal.categories.map((cater) => (cater.personal = false));
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    OfferCardView,
    SettingsModalContent,
  },

  computed: {
    selectedCategories() {
      return Offers.personal.categories.filter((cat) => cat.personal === true);
    },
  },

  watch: {},
  mounted() {
    this.is_loading = true;
    Offers.getPersonalTapeSettings((rsp) => {
      if (rsp.personal.categories.length == 0 && rsp.personal.to_income == 'none') {
        this.showModal("personal");
        this.is_loading = false;
      } else
        Offers.getPersonalTape({}, () => {
          this.is_loading = false;
        });
    });
  },
};
</script>

<style lang="scss"></style>
