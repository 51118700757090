<template>
  <transition name="zoom-fade" mode="out-in">
    <b-card no-body style="padding: 0 !important; margin: 0 !important">
      <b-card-text class="personal-description">
        Ответьте на вопросы, чтобы настроить вашу персональную ленту.
        <br />
        Вы можете изменить их в любой момент
      </b-card-text>

      <!-- stage 1 -->

      <div v-if="stage == 1" style="display: contents;">
        <b-card-title style="font-weight: 500">
          В какой из этих сфер у вас есть опыт <br />(или в каких хотели бы
          работать)?
        </b-card-title>

        <b-card-body class="categories-content" style="padding: 0">
          <div class="personal-categories">
            <b-form-checkbox
              v-for="(cat, i) in personal.categories"
              :key="i"
              class="border-radius"
              v-model="personal.categories[i].personal"
              :name="`checkbox-${i}`"
            >
              {{ cat.name }}
            </b-form-checkbox>
          </div>
        </b-card-body>
        <div class="eliminator"></div>
        <div class="d-flex buttons">
          <b-button
            variant="outline-primary"
            style="border-radius: 8px; width: 50%; margin-right: 8px"
            @click="unCheckAll"
          >
            Очистить все
          </b-button>

          <b-button
            variant="primary"
            style="border-radius: 8px; width: 50%; margin-left: 8px"
            @click="stage = 2"
          >
            Далее
          </b-button>
        </div>
      </div>

      <!-- stage 2 -->

      <div v-if="stage == 2" style="display: contents;">
        <b-card-title style="font-weight: 500">
          Укажите сумму вознаграждения:
        </b-card-title>

        <b-card-body class="categories-content" style="padding: 0">
            <b-form-checkbox
              class="border-radius mb-1"
              v-model="personal.to_income"
              name="checkbox-to_income"
              value='none'
              unchecked-value='5000'
            >
              Сумма вознаграждения не важна
            </b-form-checkbox>
            <b-form-group class="" v-slot="{ ariaDescribedby }">
              <b-form-radio class="mb-1" v-model="personal.to_income" :disabled="personal.to_income == 'none' ? 'disabled' : false" :aria-describedby="ariaDescribedby" name="some-radios" value="500"> До 500 ₽ </b-form-radio>
              <b-form-radio class="mb-1" v-model="personal.to_income" :disabled="personal.to_income == 'none' ? 'disabled' : false" :aria-describedby="ariaDescribedby" name="some-radios" value="1000"> До 1000 ₽ </b-form-radio>
              <b-form-radio class="mb-1" v-model="personal.to_income" :disabled="personal.to_income == 'none' ? 'disabled' : false" :aria-describedby="ariaDescribedby" name="some-radios" value="2000"> До 2000 ₽ </b-form-radio>
              <b-form-radio class="mb-1" v-model="personal.to_income" :disabled="personal.to_income == 'none' ? 'disabled' : false" :aria-describedby="ariaDescribedby" name="some-radios" value="3000"> До 3000 ₽ </b-form-radio>
              <b-form-radio class="mb-1" v-model="personal.to_income" :disabled="personal.to_income == 'none' ? 'disabled' : false" :aria-describedby="ariaDescribedby" name="some-radios" value="5000"> До 5000 ₽ </b-form-radio>
            </b-form-group>
        </b-card-body>
        <div class="eliminator"></div>
        <div class="d-flex buttons">
          <b-button
            variant="outline-primary"
            style="border-radius: 8px; width: 50%; margin-right: 8px"
            @click="stage = 1"
          >
            Назад
          </b-button>

          <b-button
            variant="primary"
            style="border-radius: 8px; width: 50%; margin-left: 8px"
            @click="save()"
          >
            Применить
          </b-button>
        </div>
      </div>
    </b-card>
  </transition>
</template>

<script>

export default {
  data() {
    return {
      stage: 1,
      // to_income: 'none'
    };
  },
  props: {
    personal: Object,
  },
  methods: {
    unCheckAll() {
      this.$emit("unCheckCategories");
    },
    save() {
      this.$emit("changePersonalTape");
    },
  },
  watch: {
  }
};
</script>

<style lang="scss">
.setting-personal-container {
  .modal-dialog {
    max-width: 648px !important;
  }

  .modal-title {
    @media screen and (max-width: 340px) {
      max-width: 278px;
    }
  }

  .personal-categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    grid-gap: 30px; /* Safari 10-11 */

    div {
      &:last-child {
        @media screen and (max-width: 530px) {
          margin-bottom: 28px;
        }
      }
    }

    @media screen and (max-width: 530px) {
      gap: 16px;
      grid-gap: 16px; /* Safari 10-11 */
      grid-template-columns: 1fr;
    }
  }

  .personal-description {
    margin-bottom: 28px !important;

    @media screen and (max-width: 530px) {
      line-height: 16px;
    }
  }

  .eliminator {
    display: none;

    @media screen and (max-width: 530px) {
      display: block;
      width: calc(100% + 24px);
      height: 1px;
      background-color: #a3aec4;
      margin-left: -12px;
    }
  }

  .buttons {
    margin-top: 32px;

    @media screen and (max-width: 650px) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 20px;

      button {
        margin: 0 !important;
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 530px) {
    .modal-body {
      display: flex;
    }

    .categories-content {
      overflow-y: scroll;
      height: 1vh;
      flex: 1 1 auto;
    }
  }
}
</style>
